import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment.prod';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
const webServiceEndPointURL = environment.webServiceEndPointURL;

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  readonly rootUrl = webServiceEndPointURL + '/dashboard';
  constructor(private http: HttpClient) { }

  /*
      Get Dashboard count 
  */
  getDashboardCount() {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'))
      .set('Content-Type', 'application/json');
    return this.http.get(this.rootUrl + '/all/count', { headers: headers });
  }

  exportDaywiseProductSales(data) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.post(this.rootUrl + '/export/list', JSON.stringify(data), {
      headers: headers, reportProgress: true,
      responseType: 'text'
    });
  }
}
