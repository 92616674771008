export class StockTransferInitialDTO {
  transferFromLocatorId: number;
  transferFromLocatorName: string;
  transferByUserId: number;
  transferByUserName: string;
  transferFromType: string;
  transferFromId: number;
  name: string;
  stockTransferTOList: StockTransferToDTO[] = [];
}

export class StockTransferToDTO {
  locatorId: number;
  hubId: number;
  warehouseId: number;
  locatorType: string;
  locatorName: string;
  name: string;
  transferToName: string;   //for angular use only
}

export class StockTransferDTO {
  transferFromLocatorId: number;
  transferToLocatorId: number;
  transferByUserId: number;
  returnReason: string;
  stockTransferItemDTOList: StockTransferItemDTO[] = [];
}

export class StockTransferItemDTO {
  batchNo: string;
  productId: number;
  transferQty: number;
  productName: string;
  expiryDate: string;
}

export class StockDTO {
  stockId: number;
  stockType: string;
  productId: number;
  productName: string;
  batchNo: string;
  expiryDate: string;
  totalQty: number;
  locatorId: number;
  productionStockId: number;
  warehouseStockId: number;
  centralWarehouseStockId: number;
  hubStockId: number;
}

export class StockTransfer {
  stockTransferId: number;
  documentNo: string;
  transferFromId: number;
  transferToId: number;
  totalStock: number;
  returnReason: string;
  transferUser: string;
  TransferFromName: string;
  TransferToName: string;
  transferDate: string;
}

export class StockTransferLine {
  stockTransferLineId: number;
  stockTransferId: number;
  productId: number;
  batchNo: string;
  transferQty: number;
}

export class DeliveryOrderTodaysDetailDTO {
  productId: number;
  productName: string;
  orderedQty: number;
  deliveredQty: number;
}
