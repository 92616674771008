import { Injectable } from '@angular/core';
import { UserOTP, ForgotPassword, ChangeContactDTO } from '../models/UserOTP';
import { environment } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
const webServiceEndPointURL = environment.webServiceEndPointURL;
@Injectable({
  providedIn: 'root'
})
export class OtpService {
  readonly rootUrl = webServiceEndPointURL + '/userOtp';
  constructor(private http: HttpClient) { }

  generateOTP(userOtp: UserOTP) {
    const header = new HttpHeaders()
      .set('No-Auth', 'True')
      .set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userType', 'CUSTOMER');
    return this.http.post(this.rootUrl, JSON.stringify(userOtp), { headers: header, params: param });
  }

  verifyOTP(userOtp: UserOTP) {
    const header = new HttpHeaders()
      .set('No-Auth', 'True')
      .set('Content-Type', 'application/json');
    return this.http.post(this.rootUrl + '/verify', JSON.stringify(userOtp), { headers: header });
  }

  resendOTP(userOtp: UserOTP) {
    const header = new HttpHeaders()
      .set('No-Auth', 'True')
      .set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userType', 'CUSTOMER');
    return this.http.put(this.rootUrl, JSON.stringify(userOtp), { headers: header, params: param });
  }

  sendOTPForForgotPassword(userOTP: ForgotPassword) {
    const header = new HttpHeaders()
      .set('No-Auth', 'True')
      .set('Content-Type', 'application/json');
    return this.http.put(this.rootUrl + '/forgot/password', JSON.stringify(userOTP), { headers: header });
  }

  verifyOTPForgotPassword(userOtp: ForgotPassword) {
    const header = new HttpHeaders()
      .set('No-Auth', 'True')
      .set('Content-Type', 'application/json');
    return this.http.put(this.rootUrl + '/verify/forgot/password', JSON.stringify(userOtp), { headers: header });
  }

  generateUserOTPforNewContact(otpData: ChangeContactDTO) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.rootUrl + '/change/contact', JSON.stringify(otpData), { headers: header });
  }

  verifyUserOTPforNewContact(otpData: ChangeContactDTO) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.rootUrl + '/verify/change/contact', JSON.stringify(otpData), { headers: header });
  }
}
