import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment.prod';
import { Task, AssignTask } from '../models/Task';
const webServiceEndPointURL = environment.webServiceEndPointURL;

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  readonly rootUrl = webServiceEndPointURL + '/create/task';
  constructor(private http: HttpClient) { }

  getTaskList(hubId, status) {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    let param = new HttpParams();
    if (hubId) {
      param = param.append('hubId', hubId);
    }
    if (status) {
      param = param.append('taskStatus', status);
    }
    return this.http.get(this.rootUrl + '/allType', { headers: headers, params: param });
  }

  getTaskDetailsById(id) {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl + '/' + id, { headers: headers });
  }

  addTask(task: Task) {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.post(this.rootUrl, JSON.stringify(task), { headers: header });
  }

  cancelTask(task: Task) {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.put(this.rootUrl + '/cancel/reason', JSON.stringify(task), { headers: header });
  }

  getTaskListByHub(id) {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl + '/hubwise/' + id, { headers: headers });
  }

  assignTask(task: AssignTask) {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.put(this.rootUrl + '/assign/person', JSON.stringify(task), { headers: header });
  }

  deAllocateTask(task: AssignTask) {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.put(this.rootUrl + '/remove/assignee', JSON.stringify(task), { headers: header });
  }

  exportTaskLog(fromDate: string, toDate: string) {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    const param = new HttpParams().set('fromDate', fromDate).set('toDate', toDate);
    return this.http.get(this.rootUrl + '/export', {
      headers: headers, params: param, reportProgress: true,
      responseType: 'text'
    });
  }
}
