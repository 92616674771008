export class UserOTP {
    userOtpId: number;
    contactNumber: string;
    otp: string;
    userType: string;
    active: boolean = true;
    createdAt: string;
    updatedAt: string;
}

export class ForgotPassword {
    userOtpId: number;
    userType: string;
    contactNumber: string;
    otp: string;
    active: boolean = true;
    createdAt: string;
    updatedAt: string;
    newPassword: string;
    reEnterPassword: string;
    userName: string;
}

export class OTP {
    firstDigit: string;
    secondDigit: string;
    thirdDigit: string;
    fourthDigit: string;
    fifthDigit: string;
    sixthDigit: string;
}

export class ChangeContactDTO {
    customerDetailId: number;
    active: boolean = true;
    newContactNumber: string;
    userType: string;
    otp: string;
}
