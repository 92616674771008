import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Farm } from '../models/Farm';
const webServiceEndPointURL = environment.webServiceEndPointURL;

@Injectable({
  providedIn: 'root'
})
export class FarmService {
  readonly rootUrl = webServiceEndPointURL + '/farm';
  constructor(private http: HttpClient) { }

  getFarmList() {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl, { headers: headers });
  }
  getAllFarmList() {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    const param = new HttpParams().set('allRecords', 'true')
      .set('order', 'ASC');
    return this.http.get(this.rootUrl, { params: param, headers: headers });
  }


  getFarmById(id: number) {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl + '/' + id, { headers: headers });
  }

  addFarm(farm: Farm) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.post(this.rootUrl, JSON.stringify(farm), { headers: headers });
  }

  updateFarm(farm: Farm) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.put(this.rootUrl, JSON.stringify(farm), { headers: headers });
  }

  deleteFarm(id: number) {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.delete(this.rootUrl + '/' + id, { headers: headers });
  }
}
