import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment.prod';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { InwardModel } from './../models/InwardModel';
const webServiceEndPointURL = environment.webServiceEndPointURL;
@Injectable({
  providedIn: 'root'
})
export class InwardService {
  readonly rootUrl = webServiceEndPointURL + '/central/warehouse';
  readonly rootUrlInward = webServiceEndPointURL + '/inward/stock';

  constructor(private http: HttpClient) { }

  createInwardStock(inwardModel: InwardModel) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.post(this.rootUrl + "/stock", JSON.stringify(inwardModel), { headers: headers });
  }

  getInwardStock() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl + "/stock", { headers: headers });
  }

  getInwardStockwithFilter(fromDate, toDate) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    const param = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate);
    return this.http.get(this.rootUrl + "/stock", { headers: headers, params: param });
  }

  getInwardStockwithWarehouseId(wareHouseId, locatorId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    const param = new HttpParams()
      .set('wareHouseId', wareHouseId)
      .set('locatorId', locatorId)
    return this.http.get(this.rootUrl + "/stock", { headers: headers, params: param });
  }

  getInwardStockList() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrlInward, { headers: headers });
  }

}
