export class Task {
    createTaskId: number;
    customerDetailId: number;
    hubId: number;
    subscriptionOrderId: number;
    deliveryPersonId: number;
    taskType: string;
    taskComment: string;
    cashCollection: number;
    taskStatus: string;
    taskNo: string;
    cancelReason: string;
    orderNumber: string;
    active: boolean = true;
    address: string;
    oldAddress: string;
}

export class TaskDTO {
    createTaskId: number;
    deliveryPersonId: number;
    hubId: number;
    areaName: string;
    customerFirstName: string;
    customerLastName: string;
    contactNo: string;
    taskStatus: string;
    taskNo: string;
    taskType: string;
    createdAt: string;
    personFirstName: string;
    personLastName: string;
    hubName: string;
    cashCollection: number;
    cancelReason: string;
    orderNo: string;
    amountPay: number;
    orderNumber: string;
    taskComment: string;
    updatedAt: string;
    address: string;
    oldAddress: string;
    pinCodeValue: string;
}

export class AssignTask {
    createTaskId: number;
    deliveryPersonId: number;
}