import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { CentralWareHouse } from '../models/CentralWarehouse';
const webServiceEndPointURL = environment.webServiceEndPointURL;

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
  readonly rootUrl = webServiceEndPointURL + '/warehouse';
  constructor(private http: HttpClient) { }


  getWareHouseList() {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl, { headers: headers });
  }

  getAllWarehouseList() {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    const param = new HttpParams().set('allRecords', 'true')
      .set('order', 'ASC');
    return this.http.get(this.rootUrl, { params: param, headers: headers });
  }

  getWarehouseDetailById(id) {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl + '/' + id, { headers: headers });
  }

  addWarehouse(warehouse: CentralWareHouse) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.post(this.rootUrl, JSON.stringify(warehouse), { headers: headers });
  }

  updateWarehouse(warehouse: CentralWareHouse) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.put(this.rootUrl, JSON.stringify(warehouse), { headers: headers });
  }

  getCentralWarehouseStockDetail(wareHouseId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    const param = new HttpParams()
      .set('wareHouseId', wareHouseId)
    return this.http.get(this.rootUrl + "/locator/" + wareHouseId, { headers: headers, params: param });
  }

  deleteRTOFinalStock(id) {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl + "/stock/backup/" + id, { headers: headers,reportProgress: true,
      responseType: 'text' });
  }

  getStockAvailable(locatorId){
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl+"/stock/backup/available/"+locatorId, {  headers: headers });
  }
}
