import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { CurrencyDenomination } from './../models/Currency';
import { CollectionSummary } from './../models/CollectionSummary';

const webServiceEndPointURL = environment.webServiceEndPointURL;

@Injectable({
  providedIn: 'root'
})
export class CollectionSummaryService {

  readonly rootUrl = webServiceEndPointURL + '/collectionSummary';
  readonly currencyDenominationUrl = webServiceEndPointURL + '/currency/denomination';
  constructor(private http: HttpClient) { }

  getCollectionSummaryHub(hub) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.post(this.rootUrl + '/filter', JSON.stringify(hub), { headers: headers });
  }

  addCurrencyDenomination(currencyDetail: CurrencyDenomination) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.post(this.currencyDenominationUrl, JSON.stringify(currencyDetail), { headers: headers });
  }

  getTodaycash(collectionDate){
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('userId', localStorage.getItem('userId'));
    let param = new HttpParams()
    .set('date', collectionDate);
    return this.http.get(this.currencyDenominationUrl+'/todayReceiveBtn', { headers: headers ,params:param});
  }

  getTotalAmount(collectionDate){
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('userId', localStorage.getItem('userId'));
    let param = new HttpParams()
    if(collectionDate){
     param=param.append('date', collectionDate);
    }
    return this.http.get(this.currencyDenominationUrl+'/total/amount', { headers: headers,params:param});
  }

  getCurrencyDetail(collectionDate){
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('userId', localStorage.getItem('userId'));
    let param = new HttpParams()
    if(collectionDate){
      param=param.append('date', collectionDate);
     }
    return this.http.get(this.currencyDenominationUrl+'/detail', { headers: headers,params:param});
  }
}
